<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-6 mt-n16 container-general"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-n3"
        >
          <v-card class="card-shadow v-card-grey px-0 py-0">
            <v-card-title class="pt-2 pb-2 mb-0 card-title-bloque">
              <div class="w100">
                <v-tooltip
                  v-if="$isMainWhiteLabel"
                  top
                  class="custom-tooltip"
                  style="z-index: 11;"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="mx-2 theme-border-primary-color theme-white-backgroud mt-0 mb-0 float-right"
                      fab
                      small
                      elevation="0"
                      v-bind="attrs"
                      @click="openVideoPlayer()"
                      v-on="on"
                    >
                      <v-icon class="theme-primary-color">
                        fas fa-play-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Ver videotutorial') }}</span>
                </v-tooltip>

                <p class="tit_card inline-block pt-2">
                  {{ $t('Crear plantilla de voz') }}
                </p>
              </div>
            </v-card-title>
            <div class="card-header-padding">
              <v-row>
                <v-col
                  cols="12"
                  class="mt-n3 m-10 p-10 xs"
                >
                  <voice-template
                    :show-save-bar="true"
                    :editor-mode="false"
                    :save-bar-properties="saveBarProperties"
                    :template="template"
                    :is-template="true"
                    :sms-preview="smsPreview"
                    :menu="menu"
                  />
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import VoiceTemplate from '@/components/Template/Voice/VoiceTemplate.vue'
  import { SaveBarProperties } from '@/models/SaveBarProperties'
  import { Template } from '@/models/Template'
  import { SmsPreview } from '@/classes/preview/SmsPreview'
  import { Menu } from '@/models/voice/Menu'

  export default {
    name: 'VoiceTemplatePage',
    components: {
      VoiceTemplate,
      HeaderTopDashboard,
    },
    data: function () {
      return {
        saveBarProperties: new SaveBarProperties(),
        template: new Template(),
        smsPreview: new SmsPreview(),
        menu: Menu.getMainMenu(),
      }
    },
    mounted () {},
    methods: {
      openVideoPlayer () {
        const video = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 7)

        this.$store.dispatch('videoPlayer/toggle', {
          show: true,
          url: video.url,
          title: video.title,
        })
      },
    },
  }
</script>
